import React from "react";
import "./partners-page.scss"

import ME from "./img/brand_modern.svg"
import Mint from "./img/brand_mint.svg"

import { useTranslation } from "react-i18next";

const ArrayImg = [ME,Mint]

export function Hardware(){
    const {t} = useTranslation()
    return(
        <div className="partner-info">
            <h1 className="partner-info_title">{t(`hardware.title`)}</h1>
            {
                ArrayImg.map((el,index)=>{
                    return(
                        <div className="partner-info-case" key={index}>
                            <img className="partner-info-case_img" src={el} alt="" />
                            <div className="partner-info-case_inform">
                                <h1>{t(`hardware.info.${index}.0`)}</h1>
                                <span>{t(`hardware.info.${index}.1`)}</span>
                                <a href={t(`hardware.info.${index}.2`)}>{t(`partners-points-of-sales.info.${index}.2`)}</a>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
import React, { useState } from "react";
import Up from './up.svg';
import "./button-up.scss"
import { useEffect } from "react";

export function ButtonUp(){
    const [pos,setPos] = useState(false)

    useEffect(()=>{
        document.addEventListener("scroll",()=>{
            if(window.pageYOffset >= 900){
                setPos(true)
            }else{
                setPos(false)
            }
        })
    })
        
    

    return(
        <button className={pos ? "button-up":"button-up button-up__dis"} onClick={()=>{window.scrollTo({top:0,behavior:"smooth"})}}>
            <img src={Up} alt="" />
        </button>
    )
}
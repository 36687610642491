import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../button/leng/leng-switch";
import { MiniHeader } from "./header-mini";

import "./header.scss"

import LogoH from "./img/logo.png"

export function HeadersTrue(){
    const [widthInner, setWidthInner] = useState(false)
    useEffect(()=>{
        if(window.innerWidth <= 1022){
            setWidthInner(true)
        } else {
            setWidthInner(false)
        }

        window.addEventListener("resize",()=>{
             if(window.innerWidth <= 1022){
            setWidthInner(true)
            } else {
                setWidthInner(false)
            }
        })
    })

    return(
        <>
            {!widthInner? <Headers/>:<MiniHeader/>}
        </>
    )
}

export function Headers(){
    const {t} = useTranslation()
    const [posHeader, setPosHeader] = useState(false)
    const [actHeader,setActHeader] = useState(true)
    
    
    useEffect(()=>{
        
        if(document.location.pathname === "/policyEng" || document.location.pathname === "/policyUA"){
            setActHeader(false)
        }

        document.addEventListener("scroll",(event)=>{
            if(window.pageYOffset > 0){
                setPosHeader(true)
            }else{
                setPosHeader(false)
            }
        })
        
        if(window.pageYOffset > 0){
            setPosHeader(true)
        }else{
            setPosHeader(false)
        }

        const ArryLink = Array.prototype.slice.call(document.querySelectorAll("nav-header-case>button"))
               

        ArryLink.forEach((el)=>{
            el.addEventListener("click",(event)=>{
                console.log(`${event.currentTarget.id}Case`)
                document.querySelector(`#${event.currentTarget.id}Case`).scrollIntoView({block: "start", behavior: "smooth"})
            })
        })
    },[posHeader,actHeader])

    return(
        <header className={!posHeader? "header":"header header__act"} id={actHeader?'':'none'}>
           <div className="header_case">
                <div className="logo-header">
                    <a href="/"><img src={LogoH} alt="" onClick={()=>{
                        
                    }}/></a>
                </div>

                <div className="nav-header">
                    <div className="nav-header-case">
                    <a href="/#whoItsWorkCase"><button>{t('header.nav.0.0')}</button></a>
                        <a href="/#forRetailCase"><button>{t('header.nav.1.0')}</button></a>
                        <a href="/#abautAsCase"><button>{t('header.nav.2.0')}</button></a>
                        <a href="/#contactUsCase"><button>{t('header.nav.3.0')}</button></a>
                    </div>
                    <div className="leng-header">
                        <LanguageSwitcher></LanguageSwitcher>
                    </div>
                </div>
           </div>
        </header>
    )
}


import React from "react";
import { useTranslation } from "react-i18next";

import { OurPartners } from "./our-partners/our-partners";

import "./abaut-as.scss"

import Icon0 from "./img/we_develop.svg"
import Icon1 from "./img/we_office.svg"
import Icon2 from "./img/we_team.svg"
import { useEffect } from "react";


const IconArry = [Icon0,Icon1,Icon2]

export function AbautAS(){

    const {t} = useTranslation()

    useEffect(()=>{
        const allSlide = document.querySelectorAll(".abaut-icon_case")
        

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            console.log(allSlide)
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('case-big-info__act_anim')
                }
                console.log(animItem)
            }
        }

        window.addEventListener("scroll", animationAct0)

        const allSlide0 = document.querySelectorAll(".title-border")
        function animationAct0(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide0[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('info-mobile__act_anim')
                }else{
                    animItem.classList.remove('info-mobile__act_anim')
                }
            }
        }

        window.addEventListener("scroll", animationAct0) 
    })

    return(
        <>
            <div className="abaut-as" id="abautAsCase">
                <div className="abaut-as_case">
                    <div className="abaut-info">
                        <h1 className="abaut-info_title">{t("abaut-as.title")}</h1>
                        <span className="abaut-info_info">{t("abaut-as.info0")}</span>
                        <span className="abaut-info_info">{t("abaut-as.info1")}</span>
                    </div>

                    <div className="abaut-icon">
                        {
                            IconArry.map((el,index)=>{
                                return(
                                    <div className="abaut-icon_case" key={index}>
                                        <img src={el} alt="" />
                                        <span>{t(`abaut-as.icon.${index}`)}</span>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="title-border">
                        <span>{t("abaut-as.subtitle")}</span>
                    </div>
                </div>
            </div>
            <OurPartners></OurPartners>
        </>
    )
}


import React from "react";
import { useTranslation } from "react-i18next";

import "./who-its-work.scss"

import Img0 from "./img/how_1.svg"
import Img1 from "./img/how_2.svg"
import Img2 from "./img/how_3.svg"
import { MagnettoSolutions } from "./magnetto-solutions/magnetto-solutions";
import { MobileApp } from "./magnetto-mobile-app/magnetto-mobile-app";
import { useEffect } from "react";
import { useState } from "react";

const ArrImg = [Img0,Img1,Img2]

function ImgIconInfo(){
    const {t} = useTranslation()

    useEffect(()=>{
        const allSlide = document.querySelectorAll(".icon-info")

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('slider__act_anim')
                }else{
                    animItem.classList.remove('slider__act_anim')
                }
            }
        }
        window.addEventListener("scroll", animationAct)    
    })
    
    return(            
        ArrImg.map((el,index)=>{
            return(
                <div className="icon-info" key={index}>
                    <div>
                        <img src={el} alt="" />
                        {
                            index<=ArrImg.length-2? <div className="stick"></div>:""
                        }
                    </div>
                    <div className="icon-info_case">
                        <span className="icon-info_title">{index+1}. {t(`who-it-work.info-case.${index}.0`)}</span>
                        <span className="icon-info_info">{t(`who-it-work.info-case.${index}.1`)}</span>
                    </div>
                </div>
            )
        })
    )
}

export function WhoItWork(){
    const {t} = useTranslation()
    const [nowPos,setNowPos] = useState(710)
    const [hover, setHover] = useState(false)

    useEffect(()=>{
        if(nowPos - window.pageYOffset >=0){
            setHover(true)
        }

        setNowPos(document.querySelector(".who-it-work").offsetTop)

        document.addEventListener("scroll",()=>{
            if(nowPos - window.pageYOffset >=0){
                setHover(true)
            }
            console.log()
        })
    })

    return(
        <div className="who-it-work" id="whoItsWorkCase" >
            <div className={!hover? "blur" : "blur blur__act"}>
                <h1>{t("who-it-work.title")}</h1>
                <div>
                    <ImgIconInfo></ImgIconInfo>
                </div>
            </div>
            <MagnettoSolutions></MagnettoSolutions>
            <MobileApp></MobileApp>
        </div>
    )
}




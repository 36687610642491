import emailjs from "@emailjs/browser";
import React from "react"

const SERVICE_ID = "service_3bjsbyx";
const TEMPLATE_ID = "template_exmme1x";
const USER_ID = "Kv-mKesyrgF46ht8F";

emailjs.init(USER_ID);
export function sendEmail(form){
    console.log(form)
    emailjs.send(SERVICE_ID, TEMPLATE_ID, form, USER_ID)
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
}
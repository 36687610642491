import React from "react";
import { AbautAS } from "../../component/abaut-as/abaut-as";
import { ButtonUp } from "../../component/button/button-up/button-up";
import { ContactUs } from "../../component/contct-us/contct-us";
import { FAQ } from "../../component/faq/faq";
import { ForRetail } from "../../component/for-retaile/for-retail";
import { Intro } from "../../component/intro/intro";
import { WhoItWork } from "../../component/who-its-work/who-its-work";

import "./maine-app.scss"

export function MaineApp(){

    return(
        <>
            <Intro></Intro>
            <WhoItWork></WhoItWork>
            <ForRetail></ForRetail>
            <AbautAS></AbautAS>
            <ContactUs></ContactUs>
            <FAQ></FAQ>
            <ButtonUp></ButtonUp>
        </>
    )
}













import React, { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";

import "./magnetto-solutions.scss"

import img0 from "./img/scenario_store.jpg"
import img1 from "./img/scenario_gates.jpg"
import img2 from "./img/scenario_qr.png"
import img3 from "./img/scenario_freehand.jpg"
import img4 from "./img/scenario_pay.jpg"
import img5 from "./img/scenario_delivery.jpg"

const ArrImg = [img0,img1,img2,img3,img4,img5]

function BigCaseImg(){
    const {t} = useTranslation()
    const [nowPos,setNowPos] = useState()
    const [hover, setHover] = useState(false)

    useEffect(()=>{
        if(nowPos - window.pageYOffset >=0){
            setHover(true)
        }

        setNowPos(document.querySelector(".who-it-work").offsetTop)

        document.addEventListener("scroll",()=>{
            setNowPos(document.querySelector(".who-it-work").offsetTop)
            if(nowPos - window.pageYOffset >=0){
                setHover(true)
            }
            // console.log(nowPos,hover)
        })

        // const allSlide = document.querySelectorAll(".case-big-info")

        // function offset(el){
        //     const rect = el.getBoundingClientRect(),
        //     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        //     scrollTop = window.pageYOffset || document.documentElement.scrollTop
        //     return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        // }

        // function animationAct(){
        //     for(let index = 0; index< allSlide.length; index++){
        //         const animItem = allSlide[index];
        //         const animItemHeight = animItem.offsetHeight;
        //         const animItemOffset = offset(animItem).top;
        //         const animStart = 0;

        //         let animItamPoint = window.innerHeight - animItemHeight / animStart;

        //         if(animItemHeight > window.innerHeight){
        //             animItamPoint = window.innerHeight - window.innerHeight / animStart;
        //         }

        //         if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
        //             animItem.classList.add('case-big-info__act_anim')
        //         }else{
        //             animItem.classList.remove('case-big-info__act_anim')
        //         }
        //     }
        // }
        // window.addEventListener("scroll", animationAct)
    },[])
    return(
        ArrImg.map((el,index)=>{
            return(
                <div className={!hover? "case-big-info":"case-big-info case-big-info__hover"} key={index}>
                    <img src={el} alt="" />
                    <span className="case-big-info_title">{t(`who-it-work.magnetto-solutions.info.${index}.0`)}</span>
                    <span className="case-big-ifno_info">{t(`who-it-work.magnetto-solutions.info.${index}.1`)}</span>
                </div>
            )
        })
    )
}

export function MagnettoSolutions(){
    const {t} = useTranslation()
    const [nowPos,setNowPos] = useState(1696)
    const [hover, setHover] = useState(false)

    useEffect(()=>{
        if(nowPos - window.pageYOffset > 0){
            setHover(true)
        }

        setNowPos(document.querySelector(".who-it-work").offsetTop)

        document.addEventListener("scroll",()=>{
            setNowPos(document.querySelector(".who-it-work").offsetTop)
            if(nowPos - window.pageYOffset > 0){
                setHover(true)
            }
        })
    },[])
    return(
        <div className="magnetto-solutions">
            <div className="magnetto-solutions_case">
                <div className="blur blur__act">
                    <h1>{t("who-it-work.magnetto-solutions.title")}</h1>
                </div>

                <div className="case-img-info">
                    <BigCaseImg></BigCaseImg>
                </div>
            </div>
        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MaineApp } from './app/maine-app/maine-app';
import { DataProvider } from './app/partners-page/data-provider';
import { Hardware } from './app/partners-page/hardware';
import { PointsOfSales } from './app/partners-page/partners-points-of-sales';
import { PaymentSystems } from './app/partners-page/payment-systems';
import { PolicyEng } from './app/policy/policyENG';
import { FooterWebSite } from './component/footer/footer';
import { CalculateForm } from './component/form-calculete/form-calculate';
import { HeadersTrue } from './component/header/header';


import './i18n';
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('website-body'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HeadersTrue/>
      <Routes>
        <Route path='/' element={<MaineApp></MaineApp>}></Route>
        <Route path='/form' element={<CalculateForm></CalculateForm>}></Route>
        <Route path='/partners-points-of-sales' element={<PointsOfSales></PointsOfSales>}></Route>
        <Route path='/payment-systems' element={<PaymentSystems></PaymentSystems>}></Route>
        <Route path='/data-provider' element={<DataProvider></DataProvider>}></Route>
        <Route path='/hardware' element={<Hardware></Hardware>}></Route>
        <Route path='/policyEng' element={<PolicyEng></PolicyEng>}></Route>
      </Routes>
      <FooterWebSite></FooterWebSite>
    </BrowserRouter>
  </React.StrictMode>
);






import React, { useEffect } from "react";
import { useState } from "react";
import { Lang, useFormInputValidation } from "react-form-input-validation";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import { ErrorDisplay,SendDisplay } from "../contct-us/form/form";
import { sendEmail } from "../contct-us/form/send";
import "./form-calculate.scss"

// export function PopUpSend(){
//   const {t} = useTranslation()
//   const [act,setAct] = useState(false)
//   useEffect(()=>{
//     console.log(document.querySelector(".sub-button"))
//     document.querySelector(".sub-button").addEventListener("click",()=>{
//       setAct(true)
//       setTimeout(()=>{
//         setAct(false)
//       },3000)
//     })
//   })

//   return(
//     <div className={act?  "pop-up-send" : "pop-up-send pop-up-send__dis"} >
//       {t("send-mail")}
//     </div>
//   )
// }

export function CalculateForm(){

    const {t} = useTranslation()
    const [check,setCheck] = useState(false)
    const [actSend,setActSend] = useState(false)
    const [FHCheck,setFHCheck] = useState(false)
    const [errorCheck, setErrorCheck] = useState(false)
    const [fields, errors, form] = useFormInputValidation(
        {
          email: "",
          phone: "",
          message: "",
          pos:"",
          num:1,
        },
        {
          email: "required|email",
          phone: "required|numeric|digits_between:10,12",
          message: "min:0|max:500",
          pos:"min:0|max:500",
          num:"min:0",
        }
      );
    
      useEffect(() => {
        const inputCaseArr = Array.prototype.slice.call(document.querySelectorAll(".input-case"))
        inputCaseArr.forEach((el)=>{
            el.children[1].addEventListener("focus",(event)=>{
                el.children[0].classList.add("plase-holder__act")
            })
            el.children[1].addEventListener("blur",(event)=>{
                el.children[0].classList.remove("plase-holder__act")
            })
        })

        form.registerAsync("username_available", function (
          username,
          attribute,
          req,
          passes
        ) {
          setTimeout(() => {
            if (username === "foo")
              passes(false, "Username has already been taken.");
            // if username is not available
            else passes();
          }, 1000);
        });
      }, []);
    
      form.useLang(Lang.en);
    
      const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        setErrorCheck(true)
        console.log(fields)
        if (isValid) {
          
          setErrorCheck(true)
          if(check===true){
            setErrorCheck(false)
            setActSend(true)
            setTimeout(()=>{
              setActSend(false)
            },2000)
            sendEmail(fields)
            console.log(fields)
          }else{
            setErrorCheck(true)
          }
        }
      };
    
      useEffect(() => {
        if (form.isValidForm) {
          console.log("MAKE AN API CALL ==> useEffect", fields, errors, form);
        }
      }, []);
    
      return (
        <div className="form-calc-case">
          <div className="form calculete-form">
          <form
            className="form"
            noValidate
            autoComplete="off"
            onSubmit={onSubmit}
          >    
            <label className="input-case">
                <span className="plase-holder">{t("form.0")}</span>
                    <input
                    style={{border: `${errors.phone ? "1px solid red":""}`}}
                    type="tel"
                    name="phone"
                    onBlur={form.handleBlurEvent}
                    onChange={form.handleChangeEvent}
                    value={fields.phone}
                    />
                    <label className="error">
                        {errors.phone ? t("form-error.phone") : ""}
                    </label>
                </label>
                <label className="input-case">
                <span className="plase-holder">{t("form.1")}</span>
                <input
                    style={{border: `${errors.email ? "1px solid red":""}`}}
                    type="email"
                    name="email"
                    onBlur={form.handleBlurEvent}
                    onChange={form.handleChangeEvent}
                    value={fields.email_address}
                />
                <label className="error">
                    {errors.email ? t("form-error.email") : ""}
                </label>
            </label>
            <label className="text-input input-case">
                <span className="plase-holder">{t("form.2")}</span>
                <textarea
                    name="message"
                    maxLength="20"
                    value={fields.comments}
                    onChange={form.handleChangeEvent}
                    onBlur={form.handleBlurEvent}
                ></textarea>
            </label>            
            <div className="input-check">
                <div>
                    <input type="checkbox" name="form-check" id="form-check" onClick={()=>{
                        setCheck(!check)
                    }}/>
                    <span>{t("form.3")}</span>
                </div>
                <label className="error">
                    {errorCheck? t("form-error.chex-box") : ""}
                </label>
            </div>

            {
              errorCheck? <ErrorDisplay/>:""
            }

            {
              actSend? <SendDisplay/>:""
            }

            <div className="sub-button">
                <div className="sub-button_effect"></div>
                <input className="sub-button_submit" type="submit" value={t("form.4")}/>
            </div>
            </form>
          </div>
          {/* <PopUpSend></PopUpSend> */}
        </div>
    )

}
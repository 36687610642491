import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../button/leng/leng-switch";
import "./header.scss"


export function MiniHeader(){
    const {t} = useTranslation()
    const [posHeader, setPosHeader] = useState(false)
    const [actHeader,setActHeader] = useState(true)
    const [burgerAct, setBurgerAct] = useState(false)

    useEffect(()=>{
        
        if(document.location.pathname === "/policyEng" || document.location.pathname === "/policyUA"){
            setActHeader(false)
        }

        document.addEventListener("scroll",(event)=>{
            if(window.pageYOffset > 0){
                setPosHeader(true)
            }else{
                setPosHeader(false)
            }
        })
        
        if(window.pageYOffset > 0){
            setPosHeader(true)
        }else{
            setPosHeader(false)
        }
    },[])
    return(
        <header className={!posHeader? "mini-header":"mini-header header__act"} id={actHeader?'':'none'}>
            <div className="burger" onClick={(el)=>{
                setBurgerAct(!burgerAct)
                console.log(el.target.parent)
            }}>
                <div className={!burgerAct? "burger_toggle-top":"burger_toggle-top burger_toggle-top__act"}></div>
                <div className={!burgerAct? "burger_toggle-mid":"burger_toggle-mid burger_toggle-mid__act"}></div>
                <div className={!burgerAct? "burger_toggle-botton":"burger_toggle-botton burger_toggle-botton__act"}></div>
            </div>
            <div className={!burgerAct?"nav-header mini-header-nav": "nav-header mini-header-nav mini-header-nav__act"}>
                <div className="nav-header-case">
                    <a href="/#whoItsWorkCase"><button>{t('header.nav.0.0')}</button></a>
                    <a href="/#forRetailCase"><button>{t('header.nav.1.0')}</button></a>
                    <a href="/#abautAsCase"><button>{t('header.nav.2.0')}</button></a>
                    <a href="/#contactUsCase"><button>{t('header.nav.3.0')}</button></a>
                </div>
                <div className="leng-header">
                    <LanguageSwitcher></LanguageSwitcher>
                </div>
            </div>
        </header>
    )
}
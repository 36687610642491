import React from "react"
import { useTranslation } from "react-i18next"
import { Sectors } from "./sectors/sectors"
import { BusinessBenefits } from "./business-benefits/business-benefits"

import "./for-retail.scss"

import ImgDop from "./img/img.png"



export function ForRetail(){
    const {t} = useTranslation()

    return(
        <>
            
            <div className="for-retail" id="forRetailCase">
                
                <div className="for-retail_img-case">
                    <img src={ImgDop} alt="" />
                </div>
                <div className="for-retail_text">
                    <h1>{t("for-retail.title")}</h1>
                    <div className="for-retail_info">
                        <span>{t("for-retail.info.0")}</span>
                        <span>{t("for-retail.info.1")}</span>
                    </div>
                </div>
            </div>
            <Sectors></Sectors>
            <BusinessBenefits></BusinessBenefits>
        </>
    )
}


import React from "react";

import "./icon-info.scss"

import Icon0 from "./img/business_cart.svg"
import Icon1 from "./img/business_savings.svg"
import Icon2 from "./img/business_aim.svg"
import Icon3 from "./img/business_personal.svg"
import Icon4 from "./img/business_marketing.svg"
import Icon5 from "./img/business_clients.svg"
import Icon6 from "./img/business_aim-1.svg"
import Icon7 from "./img/business_connect.svg"
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const IconArry = [Icon0,Icon1,Icon2,Icon3,Icon4,Icon5,Icon6,Icon7]

export function BusinessIcon(){

    const {t} = useTranslation()

    useEffect(()=>{
        const allSlide = document.querySelectorAll(".b-case")

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('case-big-info__act_anim')
                }
            }
        }

        window.addEventListener("scroll", animationAct)        
    })

    return(
        <div className="business-icon">
            <div className="business-icon_case">
                {
                    IconArry.map((el,index)=>{
                        return(
                            <div className="b-case" key={index}>
                                <img src={el} alt="" />
                                <div className="b-info">
                                    <span className="b-info_title">{t(`for-retail.business-benefits.icon-info.${index}.0`)}</span>
                                    <span className="b-info_text">{t(`for-retail.business-benefits.icon-info.${index}.1`)}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
import React,{ useEffect,useState } from "react";
import { useTranslation } from "react-i18next";

import Cross from "./img/cross.svg"

import "./faq.scss"

export function Slide(props){
    const [act, setAct] = useState("false")
    const [focus, setFocus] = useState("false")

   


    return(
        <div className="slider">
            <div 
                onClick={()=>{setAct(!act)}}
                onMouseOver={
                    ()=>{
                        setFocus(false)
                    }
                }
                onMouseLeave={
                    ()=>{
                        setFocus(true)
                    }
                }
                
            >
                <span className="slider_quest">{props.question} </span>
                <div className={act? "cross-img":"cross-img cross-img__act-cross"} id={focus? "":"cross-focus"}><img src={Cross} alt="" /></div>
            </div>
            <span className={!act? "slider_answ slider_answ__act" : "slider_answ"}>{props.answer}</span>
        </div>
    )
}




export function FAQ(){
    const {t} = useTranslation()
    const [top , setTop] = useState(false)
    useEffect(()=>{
        const allSlide = document.querySelectorAll(".slider")

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('slider__act_anim')
                }else{
                    animItem.classList.remove('slider__act_anim')
                }
            }
        }
        window.addEventListener("scroll", animationAct)
        

        const allSlide0 = document.querySelectorAll(".faq-case")
        function animationAct0(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide0[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('info-mobile__act_anim')
                }else{
                    animItem.classList.remove('info-mobile__act_anim')
                }
            }
        }

        window.addEventListener("scroll", animationAct0) 
    },[])
    return(
        <div className="faq">
            <div className="faq-case">
                <h1>{t("faq.title")}</h1>
            </div>

            <div className="slade-case">
                <Slide question={t("faq.info.0.0")} answer={t("faq.info.0.1")}></Slide>
                <Slide question={t("faq.info.1.0")} answer={t("faq.info.1.1")}></Slide>
                <Slide question={t("faq.info.2.0")} answer={t("faq.info.2.1")}></Slide>
                <Slide question={t("faq.info.3.0")} answer={t("faq.info.3.1")}></Slide>
                <Slide question={t("faq.info.4.0")} answer={t("faq.info.4.1")}></Slide>
            </div>
        </div>
    )
}
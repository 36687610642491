import React from "react";
import { useTranslation } from "react-i18next";

import "./contct-us.scss"
import { Form } from "./form/form.jsx";

export function ContactUs(){
    const {t} = useTranslation()
    return(
        <div className="contact-us" id="contactUsCase">
            <div className="contact-us_case">
                <h1>{t("contact.title")}</h1>
                <span>{t("contact.info.0")}</span>
                <span>{t("contact.info.1")}</span>
                <span>{t("contact.info.2")}</span>
            </div>
            <Form></Form>
        </div>
    )
}


import React from "react";
import "./partners-page.scss"

import SG from "./img/brand_system_group.png"
import OS from "./img/brand_open_store.png"
import Ch from "./img/brand_chm.png"
import { useTranslation } from "react-i18next";

const ArrayImg = [SG,OS,Ch]

export function PointsOfSales(){
    const {t} = useTranslation()
    return(
        <div className="partner-info">
            <h1 className="partner-info_title">{t(`partners-points-of-sales.title`)}</h1>
            {
                ArrayImg.map((el,index)=>{
                    return(
                        <div className="partner-info-case" key={index}>
                            
                            <img className="partner-info-case_img" src={el} alt="" />
                            <div className="partner-info-case_inform">
                                <h1>{t(`partners-points-of-sales.info.${index}.0`)}</h1>
                                <span>{t(`partners-points-of-sales.info.${index}.1`)}</span>
                                <a href={t(`partners-points-of-sales.info.${index}.2`)}>{t(`partners-points-of-sales.info.${index}.2`)}</a>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
import React from "react";
import { useTranslation } from "react-i18next";

import "./business-benefits.scss"
import { BusinessIcon } from "./icon-info/icon-info";

import ContentImg from "./img/joshua-rawson-harris.jpg"

export function BusinessBenefits(){
    const {t} = useTranslation()
    return(
        <div className="business-benefits">
            <div className="business-benefits_case">
                <div className="title-border">
                    <span>{t("for-retail.business-benefits.title")}</span>
                </div>
                <div className="business-benefits_content">
                    <img src={ContentImg} alt="" />
                    <div className="business-benefits-info">
                        <span className="business-benefits-info_title">{t("for-retail.business-benefits.info-case.title")}</span>
                        <div className="business-benefits-info_case">
                            <span>{t("for-retail.business-benefits.info-case.info.0.0")}</span>
                            <span>{t("for-retail.business-benefits.info-case.info.0.1")}</span>
                        </div>
                        <div className="business-benefits-info_case">
                            <span>{t("for-retail.business-benefits.info-case.info.1.0")}</span>
                            <span>{t("for-retail.business-benefits.info-case.info.1.1")}</span>
                        </div>
                        <span>{t("for-retail.business-benefits.info-case.info.2")}</span>
                    </div>
                </div>
            </div>

            <BusinessIcon></BusinessIcon>
        </div>
    )
}


import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './language/en/translations.json';
import translationUA from './language/ua/translations.json';

if(localStorage.i18nextLng === false || localStorage.i18nextLng === null || localStorage.i18nextLng === undefined){
  localStorage.setItem("i18nextLng","ua")
}
console.log(localStorage.i18nextLng)


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: (code) => (code === 'uk' || code === 'ru' ? 'en' : 'uk'),
    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEN,
      },
      ua: {
        translation: translationUA,
      },
    },
    
  });
export default i18n;



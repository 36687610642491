import React from "react";
import { useTranslation } from "react-i18next";

import "./magnetto-mobile-app.scss"

import ImgMobileApp from "./img/img_app.png"

import Img0 from "./img/app_conv.svg"
import Img1 from "./img/app_time.svg"
import Img2 from "./img/app_protect.svg"
import Img3 from "./img/app_plans.svg"
import { useEffect } from "react";

const ImgArr = [Img0,Img1,Img2,Img3]



function ImgInfo(){
    const {t} = useTranslation()
    useEffect(()=>{
        const allSlide = document.querySelectorAll(".info-mobile")

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('info-mobile__act_anim')
                }else{
                    animItem.classList.remove('info-mobile__act_anim')
                }
            }
        }
        window.addEventListener("scroll", animationAct)
        
    })
    return(
        ImgArr.map((el,index)=>{
            return(
                <div className="info-mobile" key={index}>
                    <img src={el} alt="" />
                    <div className="info-mobile_case">
                        <span className="info-mobile_title">{t(`who-it-work.magnetto-mobile-app.info.${index}.0`)}</span>
                        <span className="info-mobile_info">{t(`who-it-work.magnetto-mobile-app.info.${index}.1`)}</span>
                    </div>
                </div>
            )
        })
    )
}

export function MobileApp(){
    const {t} = useTranslation()
    
    return(
        <div className="mobile-app">
            <div className="mobile-app_case">
                <h1>{t("who-it-work.magnetto-mobile-app.title")}</h1>

                <div className="mobile-app_info">
                    <img src={ImgMobileApp} alt="" />
                    <div>
                        <ImgInfo></ImgInfo>
                    </div>
                </div>
            </div>
        </div>
    )
}
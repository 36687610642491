import React from "react";
import { useTranslation } from "react-i18next";

import "./our-partners.scss"

import Icon0 from "./img/pos.png"
import Icon1 from "./img/ps.svg"
import Icon2 from "./img/dp.svg"
import Icon3 from "./img/h.svg"
import { useEffect } from "react";

const IconArray = [[Icon0,"/partners-points-of-sales"],[Icon1,"/payment-systems"],[Icon2,"/data-provider"],[Icon3,"/hardware"]]

export function OurPartners(){
    const {t} = useTranslation()

    useEffect(()=>{
        const allSlide = document.querySelectorAll(".partner-link")

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 4;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('case-big-info__act_anim')
                }
            }
        }

        window.addEventListener("scroll", animationAct)    
    })
    return(
        <div className="partners">
            <div className="partners_case">
                <h1>{t("our-partner.title")}</h1>
                <span className="partners_info">{t("our-partner.info")}</span>
                <div className="partner-link-case">
                    {
                        IconArray.map((el,index)=>{
                            return(
                                <a href={el[1]} className="partner-link" key={index} id={`case-partner${index}`}>
                                    <img src={el[0]} alt="" />
                                    <span className="partner-link_title">{t(`our-partner.page-link.${index}`)}</span>
                                    <span className="partner-link_lear">{t(`our-partner.dop-text`)} <span className="arrow"></span></span>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
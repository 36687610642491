import React,{ useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./footer.scss"

import LogoFooter from "./img/logo.svg"

export function FooterWebSite(){
    const {t} = useTranslation()
    // const {i18n} = useTranslation();
    // const [leng] = useState(i18n.resolvedLanguage)
    const [actFooter,setActFooter] = useState(true)
    
    

    useEffect(()=>{
        if(document.location.pathname === "/policyEng" || document.location.pathname === "/policyUA"){
            setActFooter(false)
        }
        const ArryLink = Array.prototype.slice.call(document.querySelectorAll(".footer-nav>button"))
        ArryLink.forEach((el)=>{
            el.addEventListener("click",(event)=>{
                document.querySelector(`#${event.target.id}Case`).scrollIntoView({block: "start", behavior: "smooth"})
            })
        })
    },[actFooter])

    return(
        <div className= {actFooter?"footer":"none"}>
            <div className="footer_case">
                <div className="footer-nav">
                    <a href="/#whoItsWorkCase"><button>{t('header.nav.0.0')}</button></a>
                    <a href="/#forRetailCase"><button>{t('header.nav.1.0')}</button></a>
                    <a href="/#abautAsCase"><button>{t('header.nav.2.0')}</button></a>
                    <a href="/#contactUsCase"><button>{t('header.nav.3.0')}</button></a>
                </div>
                <img src={LogoFooter} alt="" className="footer-logo" />
                <a href={`mailto:${t("footer.link.0.1")}`} type="">{t("footer.link.0.0")}</a>
                <a href={t("footer.link.1.1")} target="_blank">{t("footer.link.1.0")}</a>
                <a href={t("footer.link.2.1")} target="_blank">{t("footer.link.2.0")}</a>
                <div className="footer-con">
                    <span>{t("footer.link.3.0")}<a href={t("footer.link.4.1")} target="_blank">|{t("footer.link.4.0")}</a></span>
                </div>
            </div>
        </div>
    )
}
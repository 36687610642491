import React from "react";
import { useTranslation } from "react-i18next";

import "./sectors.scss"

import Icon0 from "./img/industry_retail.svg"
import Icon1 from "./img/industry_finance.svg"
import Icon2 from "./img/industry_restaurant.svg"
import Icon3 from "./img/industry_farmacy.svg"
import Icon4 from "./img/industry_petrol.svg"
import Icon5 from "./img/industry_charity.svg"
import { useEffect } from "react";

const ArrIcon = [Icon0,Icon1,Icon2,Icon3,Icon4,Icon5]

export function Sectors(){
    const {t} = useTranslation()
    useEffect(()=>{
        const allSlide = document.querySelectorAll(".sector_case-img")

        function offset(el){
            const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
            return { top :rect.top + scrollTop, left: rect.left + scrollLeft }
        }

        function animationAct(){
            for(let index = 0; index< allSlide.length; index++){
                const animItem = allSlide[index];
                const animItemHeight = animItem.offsetHeight;
                const animItemOffset = offset(animItem).top;
                const animStart = 2;

                let animItamPoint = window.innerHeight - animItemHeight / animStart;

                if(animItemHeight > window.innerHeight){
                    animItamPoint = window.innerHeight - window.innerHeight / animStart;
                }

                if((window.pageYOffset > animItemOffset - animItamPoint) && window.pageYOffset<(animItemOffset + animItemHeight)){
                    animItem.classList.add('case-big-info__act_anim')
                }
            }
        }

        window.addEventListener("scroll", animationAct)        
    })
    return(
        <div className="sector">
            <h1>{t(`for-retail.sector.title`)}</h1>
            <div className="sector_case">
                {
                    ArrIcon.map((el,index)=>{
                        return(
                            <div className="sector_case-img" key={index}>
                                <img src={el} alt="" />
                                <span>{t(`for-retail.sector.icon.${index}`)}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
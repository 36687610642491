import React from "react";
import { useTranslation } from "react-i18next";

import "./intro.scss"

import IntroImg from "./img/hero.png"

export function Intro(){
    const {t} = useTranslation()
    return(
        <div className="intro">
            <div className="intro-case case">
                <div className="intro-info">
                    <h5>{t("intro.mini-title")}</h5>
                    <h4>{t("intro.title")}</h4>
                    <span>
                        {t("intro.info")}
                        <br/>
                        <br/>
                        {t("intro.info0")}
                    </span>
                    <a href="./form" target='_blank'><button className="blue-button">{t("intro.button")}</button></a>
                </div>
                <img src={IntroImg} alt="" />
            </div>
        </div>
    )
}